import React, { Component, useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import "./Contact.css"
import ReCAPTCHA from "react-google-recaptcha";


const Contact = () => {
  const recaptchaRef = React.createRef();
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let tk = await recaptchaRef.current.executeAsync();

    let mailPromise = new Promise((resolve, reject) => {
      axios.post('https://giuseppesteduto.me/contact/', { ...inputs, captchaToken: tk })
        .then(res => {
          if (res.data.status === "success")
            resolve(res.data.data);
          else
            reject(res.data.data);
        })
        .catch(error => {
          console.error(error);
          reject(error.data);
        });
    });

    toast.promise(mailPromise, {
      pending: 'Sending message...',
      success: 'Message sent!',
      error: 'Oops, there was an error! \n But feel free to send me a classic e-mail!'
    }, {
      autoClose: 7500,
      theme: "colored",
    })
  }

  useEffect(() => {
    document.getElementById("email-field-replace").innerHTML = "hi@<span></span>giusep<span></span>pesteduto.me"
  }, [])


  return (
    <div>
      <h2 className="section-header">Contact me</h2>
      <div className="card-glassmorphism">
        <Form onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col lg={7}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Your name</Form.Label>
                  <Form.Control type="text" name="name" placeholder="So I know whom I'm talking to" className="custom-input" required aria-required={true} onChange={handleChange} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Your email</Form.Label>
                  <Form.Control type="email" name="email" placeholder="So I know how to get back at you" className="custom-input" required aria-required={true} onChange={handleChange} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Your message</Form.Label>
                  <Form.Control as="textarea" name="message" placeholder="So I know what this is all about!" required style={{ height: "210px", color: "white", border: "1px solid rgba(255, 255, 255, 0.2)" }} aria-required={true} onChange={handleChange} />
                </Form.Group>

                <Button className="button-submit-postcard" type="submit">
                  Submit
                </Button>

                <div style={{ display: "none" }}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Len6YYjAAAAAMHeF2oMXqz6jlCTsYv7ZNhqHloL"
                    size="invisible"
                    badge="inline"
                  />
                </div>
              </Col>
              <Col lg={5} className="contact-form-description">
                <p>Submitting this form will send me an email. If you want, you can also do it the
                  old-fashioned way: my address is <span className="email" id="email-field-replace">fuckyouscript@scrapemyass.com</span></p>
                <hr />
                <p>
                  All the messages sent via this form will be encrypted before being sent to my
                  inbox, so nobody but me will be able to read the content of your message. <br />
                  I do that using PGP encryption. If you prefer to do that by yourself,
                  <a className="link" href="https://giuseppesteduto.me/public/giuseppe-public-key.asc"> here</a> is my PGP public key.
                </p>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Contact;
