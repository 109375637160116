import './About.css';
import propic from "./assets/propic_datacentre.jpg";
import { Row, Col, Button } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";

function About() {
  return (
    <Row className="mt-5">
      <Col sm={6}>
        <h1 className="bigHeader m-0">
          Hi, I'm <span className="name-span">Giuseppe.</span>
        </h1>
        <h4 className="m-1">
          <p>
            I'm a developer with a passion for cloud solutions, AI, and new stuff.
            Right now I'm working as a freelancer and trying everything I find inspiring in the meantime.
          </p>
          <p>
            I worked at CERN and I am a graduate student at ETH Zürich. </p> <p>
            When I don't in front of a computer, I like playing volleyball and hiking. Oh, and I'
            m a coffee lover.
          </p> <hr />
          <p>
            Interested in talking with me or to <span>hire me</span> for a project? Feel free to <AnchorLink href="#contactme"
              style={{ color: "#E94560", textDecoration: "none" }}>
              <span> get in touch! </span> </AnchorLink>
          </p>
          <p>
            <a className="link" href="https://giuseppesteduto.me/public/Giuseppe_Steduto_CV.pdf">Here</a> you can find my CV!
          </p>
        </h4>

        <AnchorLink href='#contactme' offset='100'>
          <Button className="button-see-what-i-do mt-5">
            Contact / hire me!
          </Button>
        </AnchorLink>
      </Col>
      <Col sm={6}>
        <div className="photo-container">
          <img src={propic} alt="Giuseppe Steduto" />
        </div>
      </Col>
    </Row>
  );
}

export default About;
